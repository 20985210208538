import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './QuizPage.css';

function QuizPage() {
  const { category } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [answers, setAnswers] = useState([]); // Kullanıcının cevaplarını tutacak array

  // Fisher-Yates shuffle algoritması
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        setLoading(true);
        const response = await fetch(`/data/${category}.json`);
        if (!response.ok) {
          throw new Error('Sorular yüklenirken bir hata oluştu');
        }
        const data = await response.json();
        
        let selectedQuestions;
        if (data.questions) {
          const indices = shuffleArray([...Array(data.questions.length).keys()]);
          selectedQuestions = indices.slice(0, 10).map(i => {
            const question = { ...data.questions[i] };
            question.options = shuffleArray(question.options);
            return question;
          });
        } else {
          throw new Error('Geçersiz veri formatı');
        }

        setQuestions(selectedQuestions);
        setAnswers(new Array(selectedQuestions.length).fill(null));
        setLoading(false);
      } catch (error) {
        console.error('Sorular yüklenirken hata oluştu:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    loadQuestions();
  }, [category]);

  const handleAnswerClick = (selectedAnswer) => {
    // Kullanıcının cevabını kaydet
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = selectedAnswer;
    setAnswers(newAnswers);

    if (selectedAnswer === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  const handleRetry = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setAnswers(new Array(questions.length).fill(null));
    setQuestions(shuffleArray(questions.map(q => ({
      ...q,
      options: shuffleArray(q.options)
    }))));
  };

  const renderAnswerReview = () => {
    return (
      <div className="answer-review">
        <h3>Soru Değerlendirmesi</h3>
        {questions.map((question, index) => {
          const isCorrect = answers[index] === question.correctAnswer;
          return (
            <div key={index} className={`review-item ${isCorrect ? 'correct' : 'incorrect'}`}>
              <p className="review-question">{index + 1}. {question.question}</p>
              <p className="review-answer">
                Sizin cevabınız: <span className={isCorrect ? 'correct-text' : 'incorrect-text'}>
                  {answers[index]}
                </span>
              </p>
              {!isCorrect && (
                <p className="correct-answer">
                  Doğru cevap: <span className="correct-text">{question.correctAnswer}</span>
                </p>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="quiz-container">
        <div className="loading">Sorular yükleniyor...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="quiz-container">
        <div className="error">
          <p>{error}</p>
          <button onClick={() => navigate('/')}>Ana Sayfaya Dön</button>
        </div>
      </div>
    );
  }

  if (questions.length === 0) {
    return (
      <div className="quiz-container">
        <div className="error">
          <p>Bu kategoride henüz soru bulunmamaktadır.</p>
          <button onClick={() => navigate('/')}>Ana Sayfaya Dön</button>
        </div>
      </div>
    );
  }

  return (
    <div className="quiz-container">
      {showScore ? (
        <div className="score-section">
          <h2>Test Tamamlandı!</h2>
          <p>Toplam {questions.length} sorudan {score} tanesini doğru bildiniz.</p>
          {renderAnswerReview()}
          <div className="button-group">
            <button onClick={handleRetry}>Tekrar Dene</button>
            <button onClick={() => navigate('/')}>Ana Sayfaya Dön</button>
          </div>
        </div>
      ) : (
        <>
          <div className="question-section">
            <div className="question-count">
              <span>Soru {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div className="question-text">
              {questions[currentQuestion].question}
            </div>
          </div>
          <div className="answer-section">
            {questions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswerClick(option)}
                className="answer-button"
              >
                {option}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default QuizPage; 