import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

function HomePage() {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    if (category === 'english') {
      navigate('/english');
    } else {
      navigate(`/quiz/${category}`);
    }
  };

  return (
    <div className="home-container">
      <div className="home-header">
        <h1>Kelime Deposu</h1>
        <p>Türkçe ve İngilizce dil öğrenme platformuna hoş geldiniz!</p>
      </div>
      <div className="category-grid">
        <div className="category-card" onClick={() => handleCategoryClick('deyimler')}>
          <h2>Deyimler</h2>
          <p>Türkçe deyimleri öğren ve test et</p>
        </div>
        <div className="category-card" onClick={() => handleCategoryClick('atasozleri')}>
          <h2>Atasözleri</h2>
          <p>Türkçe atasözlerini öğren ve test et</p>
        </div>
        <div className="category-card" onClick={() => handleCategoryClick('esanlamli')}>
          <h2>Eş Anlamlı Kelimeler</h2>
          <p>Eş anlamlı kelimeleri öğren ve test et</p>
        </div>
        <div className="category-card" onClick={() => handleCategoryClick('zitanlamli')}>
          <h2>Zıt Anlamlı Kelimeler</h2>
          <p>Zıt anlamlı kelimeleri öğren ve test et</p>
        </div>
        <div className="category-card" onClick={() => handleCategoryClick('english')}>
          <h2>İngilizce Çalış</h2>
          <p>İngilizce kelime ve cümleleri öğren</p>
        </div>
      </div>
    </div>
  );
}

export default HomePage; 