import React from 'react';
import { useNavigate } from 'react-router-dom';
import './EnglishPage.css';

function EnglishPage() {
  const navigate = useNavigate();

  const handleUnitClick = (unit) => {
    navigate(`/quiz/${unit}`);
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="english-container">
      <div className="english-header">
        <h1>İngilizce Öğrenme</h1>
        <p>Ünite seçin ve kelimeleri öğrenmeye başlayın!</p>
      </div>
      <div className="units-grid">
        <div className="unit-card" onClick={() => handleUnitClick('ingtur6-1')}>
          <h2>6. Sınıf - 1. Ünite</h2>
          <h3>Life</h3>
          <p>Günlük hayat ve aktiviteler hakkında kelimeler</p>
        </div>
        <div className="unit-card" onClick={() => handleUnitClick('ingtur6-2')}>
          <h2>6. Sınıf - 2. Ünite</h2>
          <h3>Yummy Breakfast</h3>
          <p>Yiyecekler ve kahvaltı ile ilgili kelimeler</p>
        </div>
        <div className="unit-card" onClick={() => handleUnitClick('ingtur6-3')}>
          <h2>6. Sınıf - 3. Ünite</h2>
          <h3>Downtown</h3>
          <p>Şehir hayatı ve günlük aktiviteler ile ilgili kelimeler</p>
        </div>
        <div className="unit-card" onClick={() => handleUnitClick('ingtur6-4')}>
          <h2>6. Sınıf - 4. Ünite</h2>
          <h3>Weather and Emotions</h3>
          <p>Hava durumu, duygular ve kıyafetler ile ilgili kelimeler</p>
        </div>
      </div>
      <button className="back-button" onClick={handleBackClick}>Anasayfaya Dön</button>
    </div>
  );
}

export default EnglishPage; 